import Swiper from './swiper/Swiper';

// import './index.css';
// import React from 'react';
// import Carousel, { CarouselItem } from "./Carousel/Carousel.js"





const Gallery = () => {
    return (
         <Swiper />  
    );
}

export default Gallery;


// const Gallery = () => {
//     return (
//         <div className="gallery">
//             <Carousel>
//                 <CarouselItem><div className='imageOne'></div></CarouselItem>
//                 <CarouselItem><div className='imageTwo'></div></CarouselItem>
//                 <CarouselItem><div className='imageThree'></div></CarouselItem>
//                 <CarouselItem><div className='imageFour'></div></CarouselItem>
//                 <CarouselItem><div className='imageFive'></div></CarouselItem>
//                 <CarouselItem><div className='imageSix'></div></CarouselItem>
//                 <CarouselItem><div className='imageSeven'></div></CarouselItem>
//                 <CarouselItem><div className='imageEight'></div></CarouselItem>
//                 <CarouselItem><div className='imageNine'></div></CarouselItem>
//                 <CarouselItem><div className='imageA'></div></CarouselItem>
//                 <CarouselItem><div className='imageB'></div></CarouselItem>
//                 <CarouselItem><div className='imageC'></div></CarouselItem>
//                 <CarouselItem><div className='imageD'></div></CarouselItem>
//                 <CarouselItem><div className='imageE'></div></CarouselItem>
//                 <CarouselItem><div className='imageF'></div></CarouselItem>
//                 <CarouselItem><div className='imageG'></div></CarouselItem>
//                 <CarouselItem><div className='imageH'></div></CarouselItem>
//                 <CarouselItem><div className='imageI'></div></CarouselItem>
//                 <CarouselItem><div className='imageHomePage'></div></CarouselItem>
//                 <CarouselItem><div className='imageHomePageOne'></div></CarouselItem>
//                 <CarouselItem><div className='imageHomePageTwo'></div></CarouselItem>
//                 <CarouselItem><div className='imageTen'></div></CarouselItem>
//                 <CarouselItem><div className='imageEleven'></div></CarouselItem>
//                 <CarouselItem><div className='imageTwelve'></div></CarouselItem>
//                 <CarouselItem><div className='imageThirteen'></div></CarouselItem>
//                 <CarouselItem><div className='imageFourteen'></div></CarouselItem>
//                 <CarouselItem><div className='imageFifteen'></div></CarouselItem>
//                 <CarouselItem><div className='imageSixteen'></div></CarouselItem>
//                 <CarouselItem><div className='imageSeventeen'></div></CarouselItem>
//                 <CarouselItem><div className='imageEighteen'></div></CarouselItem>
//                 <CarouselItem><div className='imageNineteen'></div></CarouselItem>
//                 <CarouselItem><div className='imageTwenty'></div></CarouselItem>
//                 <CarouselItem><div className='imageTwentyone'></div></CarouselItem>
//                 <CarouselItem><div className='imageTwentythree'></div></CarouselItem>
//                 <CarouselItem><div className='imageTwentyfour'></div></CarouselItem>
//                 <CarouselItem><div className='imageTwentyfive'></div></CarouselItem>
//                 <CarouselItem><div className='imageTwentysix'></div></CarouselItem>
//                 <CarouselItem><div className='imageTwentyseven'></div></CarouselItem>
//                 <CarouselItem><div className='imageTwentyeight'></div></CarouselItem>
//             </Carousel>
//         </div>
//     );
// }

// export default Gallery;